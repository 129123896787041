// @flow strict

import { guard, object, string } from 'decoders';
import type { Guard } from 'decoders';
import { parse, serialize } from 'lib/graphdown';
import type { Graph } from 'lib/graphdown';

import { iso8601 } from './iso8601';

export type Document = {|
  created_at: Date,
  last_modified_at: Date,
  graph: Graph,
|};

type Document$Serialized = {|
  created_at: string,
  last_modified_at: string,
  graph: string,
|};

const serializedDocumentDecoder: Guard<Document$Serialized> = guard(
  object({
    created_at: iso8601,
    last_modified_at: iso8601,
    graph: string,
  }),
);

function decodeDoc(data: string): Document {
  const rawdoc: Document$Serialized = serializedDocumentDecoder(JSON.parse(data));
  const created_at = new Date(rawdoc.created_at);
  const last_modified_at = new Date(rawdoc.last_modified_at);
  const graph = parse(rawdoc.graph);
  return { created_at, last_modified_at, graph };
}

export function read(path: string): Document | null {
  const docPath: string = `doc:${path}`;
  const data = window.localStorage.getItem(docPath);
  if (data) {
    return decodeDoc(data);
  } else {
    return null;
  }
}

export function write(path: string, doc: Document) {
  const docPath: string = `doc:${path}`;
  const graphdownText = serialize(doc.graph);
  const created_at = doc.created_at;
  const last_modified_at = new Date();
  const data = JSON.stringify({
    created_at: created_at.toISOString(),
    last_modified_at: last_modified_at.toISOString(),
    graph: graphdownText,
  });
  window.localStorage.setItem(docPath, data);
}
