// @flow strict

import randomize from 'randomatic';

import { Graph } from './Graph';
import type { NodeId } from './Node';

// Local helper structure during parsing
type ParsedLine = {|
  +id: string, // unique ID for this node
  +indent: number,
  +isCompleted: boolean,
  +text: string,
|};

const url_re = /(?:[A-Za-z]{3,9}(?:[+][A-Za-z]{3,9})?):\/\/(?:(?:[-;:&=+$,\w]+)@)?(?:(?:[A-Za-z0-9.-]+)(?::(?:[0-9]{2,5}))?)(?:\/(?:[-+~%/.\w]*)?(?:\?[-+=&;%@.\w]*)?(?:#[-.!/\w]*)?)?/gm;

/**
 * Extracts links from a text.
 */
export function extractLinks(text: string): [string, Array<string>] {
  const links = [];
  const matches = text.match(url_re);
  if (matches) {
    for (const match of matches) {
      links.push(match);
    }
  }
  const newText = text.replace(url_re, '');
  return [newText.trim(), links];
}

function parseLine(line: string): ParsedLine | null {
  const match = line.match(/^(\s*)(?:-\s*)?(\[[ x]*\])?\s*(.+?)(?:\[(.*)\])?$/i);
  //                          ^^^ indent                   ^^ text
  //                                        ^^^^^^^^^ checkbox      ^^^^ explicit ID
  if (match) {
    const [, indent, checkbox, text, explicitId] = match;
    const id = explicitId || randomize('Aa0', 3);
    return {
      id,
      indent: indent.length,
      isCompleted: /x/i.test(checkbox),
      text,
    };
  } else {
    return null;
  }
}

function buildGraph(lines: Array<ParsedLine>, graph_: Graph): Graph {
  let graph = graph_;
  let newNode;

  // Keep a stack of (indent, ID) tuples.  This stack contains the "breadcrumb"
  // of parent items for the current line.  If the indentation level decreases,
  // we walk up the parent stack to find an entry with a lower indentation
  // level, which will become parent of the current line.
  const stack: Array<[number, NodeId]> = [];

  function findParent(indent: number): NodeId | null {
    const tup = stack.pop();
    if (!tup) return null;

    const [currIndent, nodeId] = tup;
    if (indent > currIndent) {
      // Put just-popped item back on stack before returning nodeId
      stack.push([currIndent, nodeId]);
      stack.push([indent, nodeId]);
      return nodeId;
    }

    return findParent(indent);
  }

  // For each line, try to find the parent node for it.  If no such node can be
  // found, insert it as a new root node.
  for (const line of lines) {
    const parent = findParent(line.indent);
    const parents = parent ? [parent] : [];

    [graph, newNode] = graph.createNode(line.text, parents, line.isCompleted);
    stack.push([line.indent, newNode]);
  }

  return graph;
}

export default function parse(graphdownText: string): Graph {
  const items: Array<ParsedLine> = graphdownText
    .split('\n')
    .map(parseLine)
    .filter(Boolean);
  return buildGraph(items, Graph.empty());
}
