// @flow strict

import { flatmap, max, min } from 'itertools';

import type { Point, Rect } from './geometry';
import { Node } from './Node';
import type { NodeId } from './Node';

type NodeLUT = { +[NodeId]: Node };

type GraphFields = {|
  +nodeLUT: NodeLUT,
  +leafIds: Array<NodeId>,
  +selectedNodeId: NodeId | null,
|};

type TreeNode = {|
  +node: Node,
  +children: Array<TreeNode>,
|};

export type Edge = {|
  src: Node,
  dest: Node,
  index: number, // dest is the n-th child of src
  numChildren: number, // number of children of src

  // TODO: Not sure about putting this on here.... 🤔
  relPos: number,
|};

function toLUT(nodes: Array<Node>): NodeLUT {
  const lut: { [NodeId]: Node } = {};
  for (const node of nodes) {
    lut[node.id] = node;
  }
  return lut;
}

type Primitive =
  | string
  | number
  // This is HACK
  | NodeId;

function dedupe<T: Primitive>(items: Array<T>): Array<T> {
  const seen: Set<T> = new Set();
  const result = [];
  for (const item of items) {
    if (seen.has(item)) {
      continue;
    }

    seen.add(item);
    result.push(item);
  }
  return result;
}

export class Graph {
  nodeLUT: NodeLUT;
  leafIds: Array<NodeId>;
  selectedNodeId: NodeId | null;

  constructor(fields: GraphFields) {
    this.nodeLUT = fields.nodeLUT;
    this.leafIds = fields.leafIds;
    this.selectedNodeId = fields.selectedNodeId;
  }

  /**
   * Convenience constructor for an empty Graph.
   */
  static empty() {
    return Graph.of(/* nothing */);
  }

  /**
   * Given a list of nodes (with internal links to each other), build a graph.
   */
  static of(...nodes: Array<Node>) {
    const nodeLUT: NodeLUT = toLUT(nodes);

    const parentIds: Set<NodeId> = new Set();
    for (const node of nodes) {
      if (node.parents.length > 0) {
        parentIds.add(...node.parents);
      }
    }

    if (Array.from(parentIds).some(pid => nodeLUT[pid] === undefined)) {
      throw new Error("Some parent references don't exist");
    }

    const leafIds = nodes.map(n => n.id).filter(n => !parentIds.has(n));
    if (leafIds.length === 0 && Object.keys(nodeLUT).length > 0) {
      throw new Error('No leafs: circular references in Graph are illegal');
    }

    return new Graph({ nodeLUT, leafIds, selectedNodeId: null });
  }

  /**
   * Updates the graph with the given properties.
   */
  update(record: $Shape<GraphFields>): Graph {
    return new Graph({
      nodeLUT: record.nodeLUT !== undefined ? record.nodeLUT : this.nodeLUT,
      leafIds: record.leafIds !== undefined ? record.leafIds : this.leafIds,
      selectedNodeId: record.selectedNodeId !== undefined ? record.selectedNodeId : this.selectedNodeId,
    });
  }

  /**
   * Creates a new node and adds it to the graph.
   */
  createNode(
    rawTitle: string,
    parents: Array<NodeId> = [],
    isCompleted: boolean = false,
    links: Array<string> = [],
  ): [Graph, NodeId] {
    const node = Node.create(rawTitle, parents, isCompleted);
    const nodeLUT = { ...this.nodeLUT, [node.id]: node };

    // By mapping and de-duping this list, we'll make sure that the new leaf
    // node gets injected in place of the previous leaf node, making sure that
    // the graph's branches stay in stable positions.  If we did not do this.
    const leafIds = dedupe([...this.leafIds.map(id => (parents.includes(id) ? node.id : id)), node.id]);
    return [this.update({ nodeLUT, leafIds }), node.id];
  }

  /**
   * Returns the number of nodes in the graph.
   */
  size(): number {
    return Object.keys(this.nodeLUT).length;
  }

  /**
   * Returns an array of all node IDs existing in the graph.
   */
  allNodeIds(): Array<NodeId> {
    const seen = new Set();

    // Helper to recurse
    const walk = (id: NodeId): Array<NodeId> => {
      if (seen.has(id)) {
        return [];
      }
      seen.add(id);
      const node = this.get(id);
      return [id, ...Array.from(flatmap(node.parents, pid => walk(pid)))];
    };

    return Array.from(flatmap(this.leafIds, id => walk(id)));
  }

  /**
   * Returns whether the given node, and all of its decendants, are completed.
   */
  isFullyCompleted(node: Node): boolean {
    return node.isCompleted && this.getChildren(node.id).every(child => this.isFullyCompleted(child));
  }

  /**
   * Returns whether the given node, and all of its decendants, are completed.
   */
  isNodeVisible(node: Node, showCompleted: boolean): boolean {
    return showCompleted
      ? true
      : // Never hide root nodes
        node.parents.length === 0 ||
          // Never hide nodes that have uncompleted children
          !this.isFullyCompleted(node);
  }

  /**
   * Returns an array of all node IDs existing in the graph.  If `onlyVisible`
   * is set, only return nodes that are not completed yet.
   */
  allNodes(showCompleted: boolean = true): Array<Node> {
    const nodes = this.allNodeIds().map(id => this.get(id));
    return nodes.filter(node => this.isNodeVisible(node, showCompleted));
  }

  /**
   * Returns an array of all node IDs existing in the graph.
   */
  getOrNull(id: NodeId): Node | null {
    const node = this.nodeLUT[id];
    return node ? node : null;
  }

  get(id: NodeId): Node {
    const node = this.getOrNull(id);
    if (!node) throw new Error(`No such Node: ${String(id)}`);
    return node;
  }

  getLeafs(): Array<Node> {
    return this.leafIds.map(id => this.get(id));
  }

  getRoots(): Array<Node> {
    return this.allNodes().filter(node => node.parents.length === 0);
  }

  getByTitle(title: string): Node {
    const node = this.allNodes().find(node => node.title === title);
    if (!node) throw new Error(`No Node by the title of "${title}"`);
    return node;
  }

  allEdges(showCompleted: boolean): Array<Edge> {
    return Array.from(
      flatmap(this.allNodes(showCompleted), parent => {
        const children = this.getChildren(parent.id).filter(child => this.isNodeVisible(child, showCompleted));
        const numChildren = children.length;
        const centers = children.map(child => child.center.x);
        const xmin = min(centers) || 0;
        const xmax = max(centers) || 0;
        const span = xmax - xmin;
        const srcBase = parent.center.x - xmin;
        return children.map((child, index) => {
          const base = child.center.x - xmin;
          const wiggleroom = child.size.width / 2;
          const dx = span ? wiggleroom * ((srcBase - base) / span) : 0;
          return {
            src: parent,
            dest: child,
            index,
            numChildren,
            relPos: dx,
          };
        });
      }),
    );
  }

  selectedNode(): Node | void {
    if (this.selectedNodeId === null) return undefined;
    return this.get(this.selectedNodeId);
  }

  selectNode(id: NodeId | null): Graph {
    const node = id === null ? null : this.getOrNull(id);
    const selectedNodeId: NodeId | null = node ? node.id : null;
    return this.update({ selectedNodeId });
  }

  getBoundingBox(): Rect {
    const nodes = this.allNodes();
    const topLefts = nodes.map(node => node.topLeft);
    const bottomRights = nodes.map(node => node.bottomRight);
    const x = min(topLefts.map(tl => tl.x)) || 0;
    const y = min(topLefts.map(tl => tl.y)) || 0;
    const right = max(bottomRights.map(tl => tl.x)) || 800;
    const bottom = max(bottomRights.map(tl => tl.y)) || 600;
    const width = right - x;
    const height = bottom - y;
    return { x, y, width, height };
  }

  replaceNode(nodeId: NodeId, mapper: Node => Node): Graph {
    const node = this.get(nodeId);
    const newNode = mapper(node);
    if (node.id !== newNode.id) {
      throw new Error('Internal node ID cannot be changed during replaceNode()');
    }
    const nodeLUT = { ...this.nodeLUT, [node.id]: newNode };
    return this.update({ nodeLUT });
  }

  deleteNode(nodeId: NodeId): Graph {
    const newParents = this.get(nodeId).parents;
    const nodes: Array<Node> = this.allNodes()
      .map(node => {
        if (node.id === nodeId) {
          return null;
        } else {
          const parents = node.parents;
          if (parents.includes(nodeId)) {
            return node.setParents([...node.parents.filter(pid => pid !== nodeId), ...newParents]);
          } else {
            // No change to this node
            return node;
          }
        }
      })
      .filter(Boolean);

    return Graph.of(...nodes).selectNode(newParents[0] || null);
  }

  toggleDone(nodeId: NodeId): Graph {
    return this.replaceNode(nodeId, node => node.toggle());
  }

  shift(delta: Point): Graph {
    const nodeLUT = toLUT(this.allNodes().map(node => node.shift(delta)));
    return this.update({ nodeLUT });
  }

  getChildren(nodeId: NodeId): Array<Node> {
    return this.allNodes().filter(n => n.parents.includes(nodeId));
  }

  nodeToTreeNode(node: Node, showCompleted: boolean): TreeNode {
    return {
      node,
      children: this.getChildren(node.id)
        .filter(node => this.isNodeVisible(node, showCompleted))
        .map(x => this.nodeToTreeNode(x, showCompleted)),
    };
  }

  /**
   * Converts the graph to a tree structure, having nodes with child nodes.
   */
  toTree(showCompleted: boolean): Array<TreeNode> {
    return this.getRoots().map(children => this.nodeToTreeNode(children, showCompleted));
  }
}
