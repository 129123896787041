// @flow

import GraphCtx from 'contexts/Graph';
import SetGraphCtx from 'contexts/SetGraph';
import { parse, prettify, serialize } from 'lib/graphdown';
import { FlexDown } from 'lib/ui';
import React, { useContext, useState } from 'react';
import * as Icon from 'react-feather';
import { Button } from 'reactstrap';
import styled from 'styled-components/macro';

type Props = {||};

const DebuggerArea = styled.div`
  background-color: papayawhip;
  border-left: 2px solid #ddd;

  button {
    margin: 10px 20px;
  }
`;

export default function Debugger(props: Props) {
  // Global graph state, when user hits the "Update" button
  const graph = useContext(GraphCtx);
  const [prevGraph, setPrevGraph] = useState(graph);
  const setGraph = useContext(SetGraphCtx);

  // Local-to-debugger graph state, as it's being modified
  const [text, setText] = useState(() => serialize(graph));

  // Update local-to-debugger state if external state is modified
  if (graph !== prevGraph) {
    setPrevGraph(graph);
    setText(serialize(graph));
  }

  function handleChange(event: SyntheticEvent<HTMLTextAreaElement>) {
    setText(event.currentTarget.value);
  }

  const formatted = prettify(text);
  return (
    <DebuggerArea>
      <FlexDown>
        <h3 style={{ textAlign: 'center', margin: 10 }}>Debugger</h3>
        <Button
          outline
          color="primary"
          size="sm"
          onClick={() => {
            setGraph(parse(text));
          }}
        >
          <Icon.Zap /> Update
        </Button>
        <hr />
        <textarea style={{ height: 400, fontSize: 14 }} value={text} onChange={handleChange} />
        <pre style={{ fontSize: 14 }}>{formatted}</pre>
      </FlexDown>
    </DebuggerArea>
  );
}
