// @flow strict

export default {
  FONT_FAMILY: 'News Cycle',
  FONT_SIZE: 16,
  LINE_HEIGHT: 20,
  NODE_PADDING_TOP: 3,
  NODE_PADDING_BOTTOM: 6,
  NODE_PADDING_LEFT: 8,
  NODE_PADDING_RIGHT: 8,
  HPADDING: 8,
  BORDER_SIZE: 2,
  MAX_NODE_WIDTH: 220,
};
