// @flow

import React from 'react';
import * as Icon from 'react-feather';
import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import graphIllustration from '../img/graph-illustration.svg';
import logo from '../img/logo-white.svg';
import bookCover from '../img/the-mikado-method-book-cover.jpg';
import { FlexLeft, Vsplit } from '../lib/ui';

const ContainerBg = styled.div`
  background-image: linear-gradient(90deg, #453c99 0%, #187688 100%);
  color: white;

  h1 {
    color: white;
  }

  .container {
    padding-bottom: 80px;

    @media (min-width: 1200px) {
      background: url(${graphIllustration}) no-repeat 100% 70%;
    }
  }
`;

const BookShelf = styled.div`
  -moz-perspective: 120px;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
`;

const BookCover = styled.div`
  display: inline-block;
  box-shadow: 5px 5px 20px #333;
  margin: 10px;

  img {
    vertical-align: middle;
  }

  /**
   *  In order for this to work, you must use Modernizer
   *  to detect 3D transform browser support. This will add
   *  a "csstransforms3d" class to the HTML element.
   *
   *  Visit http://modernizr.com/ for installation instructions
   */

  position: relative;
  -moz-perspective: 100px;
  -moz-transform: rotateY(-3deg);
  -webkit-transform: perspective(100) rotateY(-3deg);
  outline: 1px solid transparent; /* Helps smooth jagged edges in Firefox */
  box-shadow: none;
  margin: 0;

  img {
    position: relative;
    max-width: 100%;
  }

  &:before,
  &:after {
    position: absolute;
    top: 2%;
    height: 96%;
    content: ' ';
    z-index: -1;
  }

  &:before {
    width: 100%;
    left: 7.5%;
    background-color: #5a2d18;
    box-shadow: 5px 5px 20px #333;
  }

  &:after {
    width: 5%;
    left: 100%;
    background-color: #efefef;
    box-shadow: inset 0px 0px 5px #aaa;
    -moz-transform: rotateY(20deg);
    -webkit-transform: perspective(100) rotateY(20deg);
  }
`;

const FooterBg = styled.div`
  background-color: #fcfdfe;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.08);
`;

export default () => (
  <>
    <ContainerBg>
      <Container>
        <Row>
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            <div style={{ marginTop: 160, marginBottom: 50 }}>
              <Vsplit>
                <FlexLeft>
                  <img alt="Mikado scratchpad" height={120} src={logo} />
                </FlexLeft>
              </Vsplit>
            </div>
            <h1 style={{ fontSize: 44, marginBottom: 40 }}>
              <span className="text-thin">Refactor anything with</span>
              <br />
              <span className="text-bold">The Mikado Method.</span>
            </h1>
            <NavLink style={{ marginRight: 24 }} className="btn btn-primary btn-lg" to="/docs">
              Get Started
            </NavLink>
            <NavLink
              style={{ marginRight: 24 }}
              className="btn btn-outline-light btn-lg"
              onClick={e => {
                e.preventDefault();
                alert('TODO');
              }}
              to="/docs"
            >
              Learn More
            </NavLink>
          </Col>
        </Row>
      </Container>
    </ContainerBg>
    <Container>
      <Row style={{ marginBottom: 50 }}>
        <Col sm="12" md={{ size: 8, offset: 2 }} style={{ paddingTop: 50 }}>
          <h2>What is the Mikado Method?</h2>
          <p className="intro">
            <div style={{ float: 'right', margin: '16px 24px' }}>
              <BookShelf>
                <BookCover>
                  <img widtth={200} height={250} alt="The Mikado Method book cover" src={bookCover} />
                </BookCover>
              </BookShelf>
            </div>
            In their book <a href="https://www.manning.com/books/the-mikado-method">"The Mikado Method"</a>, Ola
            Ellnestam and Daniel Brolund explain the Mikado method, a method for structurally approaching non-trivial
            technical improvements to any large scale legacy system.
          </p>
          {/* <p>The metaphor is that of the game Mikado, also known as "pick-up sticks".</p> */}
          <p className="intro">
            The main artefact of using the method is the so called Mikado Graph, which is typically drawn on a piece of
            paper. After drawing many of these on paper, I fell in love with the method. But I also wanted a way to more
            easily change these graphs, link to code sections, and share them with team members, for example by
            attaching them to pull requests.
          </p>
          <p>
            <NavLink className="btn btn-primary btn-lg" to="/docs">
              Try now!
            </NavLink>
          </p>
        </Col>
      </Row>
    </Container>
    <FooterBg>
      <Container>
        <Row style={{ marginBottom: 50 }}>
          <Col sm="12" md={{ size: 8, offset: 2 }} style={{ fontSize: 14, paddingTop: 30, textAlign: 'center' }}>
            Made with <Icon.Heart size={20} fill="#ff4233" stroke="#b70d06" /> by{' '}
            <a href="https://nvie.com/about" style={{ fontWeight: 600 }}>
              nvie
            </a>
            .
          </Col>
        </Row>
      </Container>
    </FooterBg>
  </>
);
