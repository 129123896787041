// @flow

import React from 'react';
import { Col, Container, Row } from 'reactstrap';

export default () => (
  <>
    <Container>
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <h1>Oops?</h1>
        </Col>
      </Row>
    </Container>
  </>
);
