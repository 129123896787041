// @flow strict

/**
 * Graphdown
 * A simple markdown-like notation for Graphs.
 *
 * Syntax:
 *
 * [ ] This is a root
 *     [ ] Indented child note
 *     [x] This item is done
 *         [x] This child is also done if the parent is done
 * [ ] Another root
 *
 */

import type { Graph } from './Graph';
import type { Node } from './Node';

function serializeNode(graph: Graph, node: Node, level: number = 0): string {
  // Current node to output
  const prefix = '    '.repeat(level);
  const checkbox = `[${node.isCompleted ? 'x' : ' '}]`;
  const line = `${prefix}${checkbox} ${node.rawTitle()}`;
  const children = graph.getChildren(node.id);
  return [line, ...children.map(child => serializeNode(graph, child, level + 1))].join('\n');
}

export default function serialize(graph: Graph): string {
  return graph
    .getRoots()
    .map(root => serializeNode(graph, root))
    .join('\n');
}
