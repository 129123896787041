// @flow

import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter } from 'react-router-dom';

import Routes from './Routes';

const App = () => (
  <HttpsRedirect>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </HttpsRedirect>
);

export default App;
