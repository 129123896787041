// @flow

import React from 'react';

import Hsplit from './Hsplit';
import Vsplit from './Vsplit';

type WithChildren = {
  children: React$Node,
};

export const FlexDown = ({ children }: WithChildren) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    }}
  >
    {children}
  </div>
);

export const FlexRight = ({ children }: WithChildren) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
    }}
  >
    {children}
  </div>
);

export const FlexLeft = ({ children }: WithChildren) => (
  <div
    className="FlexLeft"
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    {children}
  </div>
);

export { Hsplit, Vsplit };
