// @flow

import { Graph, Node } from 'lib/graphdown';
import { useWindowSize } from 'lib/hooks';
import autoLayout from 'lib/layout';
import { read, write } from 'lib/storage';
import type { Document } from 'lib/storage';
import { Hsplit, Vsplit } from 'lib/ui';
import React, { useEffect, useState } from 'react';
import { HotKeys } from 'react-hotkeys';
import type { ContextRouter } from 'react-router-dom';

import GraphCtx from '../contexts/Graph';
import SetGraphCtx from '../contexts/SetGraph';
import AppContainer from './AppContainer';
import Canvas from './Canvas';
import Debugger from './Debugger';
import Menu from './Menu';
import Toolbar from './Toolbar';

type Props = {| ...ContextRouter |};

/**
 * Global keyboard shortcut mapping
 */
const map = {
  addChildNode: 'a',
  toggleDone: 'x',
  editSelection: 'e',
  deleteSelectedNode: ['del', 'd d'],

  clearSelection: 'esc',
  selectChildNode: 'up',
  selectParentNode: 'down',
  selectPrevSiblingNode: 'left',
  selectNextSiblingNode: 'right',
};

function newDocument(): Document {
  return {
    created_at: new Date(),
    last_modified_at: new Date(),
    graph: Graph.of(Node.create('My goal')),
  };
}

export default function Doc(props: Props) {
  const docId = props.match.params.docId;
  if (!docId) {
    throw new Error('No document ID');
  }
  const [doc] = useState(() => read(docId) || newDocument());
  const [showCompletedNodes, setShowCompletedNodes_] = useState(true);
  const [graph, setGraph_] = useState(() => autoLayout(doc.graph, showCompletedNodes));
  const [debuggerVisible, setDebuggerVisible] = useState(false);
  const windowSize = useWindowSize();

  /**
   * Invoked every time the graph changes.
   * Perfect opportunity to write the graph's new state to local storage.
   */
  useEffect(() => {
    const newDoc = {
      created_at: doc.created_at,
      last_modified_at: doc.last_modified_at,
      graph,
    };
    write(docId, newDoc);
  }, [doc.created_at, doc.last_modified_at, docId, graph]);

  function setGraph(value: Graph | (Graph => Graph)) {
    const newGraph = typeof value === 'function' ? value(graph) : value;
    setGraph_(autoLayout(newGraph, showCompletedNodes));
  }

  function setShowCompletedNodes(value: boolean) {
    // Setting the "completed nodes" setting should also re-layout things
    setShowCompletedNodes_(value);
    setGraph_(autoLayout(graph, value));
  }

  function toggleDone() {
    const selectedNodeId = graph.selectedNodeId;
    if (!selectedNodeId) {
      return;
    }

    setGraph(graph => graph.toggleDone(selectedNodeId));
  }

  function addChildNode() {
    const selectedNode = graph.selectedNode();
    if (!selectedNode) {
      return;
    }

    const title = window.prompt('Describe your prerequisite:', '');
    if (title) {
      setGraph(graph => {
        const [newGraph, newChildId] = graph.createNode(title, [selectedNode.id]);
        return newGraph.selectNode(newChildId);
      });
    }
  }

  function deleteSelectedNode() {
    const selectedNode = graph.selectedNode();
    if (!selectedNode) {
      return;
    }

    if (window.confirm(`Are you sure to delete node "${selectedNode.title}"?`)) {
      setGraph(graph.deleteNode(selectedNode.id));
    }
  }

  function editSelection() {
    const selectedNode = graph.selectedNode();
    if (!selectedNode) {
      return;
    }

    const newTitle = window.prompt('Enter a new title:', selectedNode.rawTitle());
    if (newTitle !== null) {
      setGraph(graph => graph.replaceNode(selectedNode.id, node => node.setRawTitle(newTitle)));
    }
  }

  function setSelectedNode(changer: (Node | null, Graph) => Node | null) {
    const curr: Node | null = graph.selectedNode() || null;
    const newSelection: Node | null = changer(curr, graph);
    setGraph(graph => graph.selectNode(newSelection ? newSelection.id : null));
  }

  function clearSelection() {
    setSelectedNode(() => null);
  }

  function selectParentNode() {
    setSelectedNode((curr, graph) => {
      if (!curr) {
        // If there currently is no selection, select a root note
        return graph.getRoots()[0] || null;
      } else {
        const parents = curr.parents;
        if (parents.length > 0) {
          const parentId = curr.parents[Math.floor((curr.parents.length - 1) / 2)];
          return graph.get(parentId);
        } else {
          return curr;
        }
      }
    });
  }

  function selectChildNode() {
    setSelectedNode((curr, graph) => {
      if (!curr) {
        return graph.getRoots()[0] || null;
      } else {
        const kids = graph.getChildren(curr.id);
        if (kids.length > 0) {
          return kids[Math.floor((kids.length - 1) / 2)];
        } else {
          return curr;
        }
      }
    });
  }

  function selectSibling(offset: number) {
    setSelectedNode((curr, graph) => {
      if (!curr) {
        return graph.getRoots()[0] || null;
      } else {
        // TODO: This logic is currently sibling-based, but it might be better
        // to base it on the visual (x, y) coordinate proximity instead!

        let siblings: Array<Node> = [];
        const parents = curr.parents;
        if (parents.length > 0) {
          const parentId = curr.parents[0];
          siblings = graph.getChildren(parentId);
        } else {
          // Look for sibling root nodes
          siblings = graph.getRoots();
        }

        if (siblings.length > 1) {
          const index = siblings.findIndex(n => n.id === curr.id);
          const newIndex = Math.max(0, Math.min(siblings.length - 1, index + offset));
          return siblings[newIndex];
        } else {
          return curr;
        }
      }
    });
  }

  const handlers = {
    addChildNode,
    toggleDone,
    editSelection,
    deleteSelectedNode,
    clearSelection,
    selectParentNode,
    selectChildNode,
    selectPrevSiblingNode: () => selectSibling(-1),
    selectNextSiblingNode: () => selectSibling(+1),
  };

  const headerHeight = 58;
  const toolbarHeight = 34;
  const bodyHeight = windowSize.height - headerHeight;
  const canvasHeight = bodyHeight - toolbarHeight;
  return (
    <AppContainer
      heights={[`${headerHeight}px`, `${bodyHeight}px`]}
      style={{ width: windowSize.width, height: windowSize.height }}
    >
      <header>
        <Menu docId={docId} />
      </header>
      <div style={{ height: bodyHeight }}>
        <GraphCtx.Provider value={graph}>
          <SetGraphCtx.Provider value={setGraph}>
            <HotKeys keyMap={map}>
              <Hsplit
                className="i-split-toolbar-from-main-stuff"
                heights={[`${toolbarHeight}px`, `${bodyHeight - toolbarHeight}px`]}
              >
                <Toolbar
                  showCompletedNodes={showCompletedNodes}
                  setShowCompletedNodes={setShowCompletedNodes}
                  debuggerVisible={debuggerVisible}
                  setDebuggerVisible={setDebuggerVisible}
                  selectionToggleDone={toggleDone}
                  selectionEditTitle={editSelection}
                />
                <div>
                  {debuggerVisible ? (
                    <Vsplit
                      className="i-split-canvas-and-debugger"
                      widths={['1fr', '300px']}
                      style={{
                        width: windowSize.width,
                        height: canvasHeight,
                      }}
                    >
                      <Canvas
                        handlers={handlers}
                        showCompletedNodes={showCompletedNodes}
                        editSelection={editSelection}
                      />
                      <Debugger />
                    </Vsplit>
                  ) : (
                    <Vsplit
                      className="i-split-canvas-and-debugger-too"
                      widths={['1fr', '0px']}
                      style={{
                        width: windowSize.width,
                        height: canvasHeight,
                      }}
                    >
                      <Canvas
                        handlers={handlers}
                        showCompletedNodes={showCompletedNodes}
                        editSelection={editSelection}
                      />
                      <div />
                    </Vsplit>
                  )}
                </div>
              </Hsplit>
            </HotKeys>
          </SetGraphCtx.Provider>
        </GraphCtx.Provider>
      </div>
    </AppContainer>
  );
}
