// @flow

import { useWindowSize } from 'lib/hooks';
import randomize from 'randomatic';
import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { NavLink } from 'react-router-dom';
import { Alert, Col, Container, Row } from 'reactstrap';
import styled from 'styled-components/macro';

import { FlexLeft, FlexRight, Vsplit } from '../lib/ui';
import AppContainer from './AppContainer';
import Menu from './Menu';

type Props = {||};

const DocRow = styled(NavLink)`
  display: block;
  padding: 13px 20px;
  border: 1px solid #ddd;
  border-bottom: none;
  background-color: white;

  color: #444;

  &:last-of-type {
    border-bottom: 1px solid #ddd;
  }
  &:hover {
    color: #333;
    background-color: #eef6ff;
    text-decoration: none;
  }

  svg {
    margin-right: 10px;
  }
`;

const Black = styled.span`
  color: #333;
  font-weight: bold;
`;

type Doc = {|
  id: string,
  title: string,
|};

function getDocsFromLocalStorage(): Array<Doc> {
  const docs: Array<Doc> = [];
  for (const key of Object.keys(localStorage)) {
    if (key.startsWith('doc:')) {
      const doc = key.substring(4, key.length);
      const title = localStorage[`doctitle:${doc}`] || doc;
      if (doc) {
        docs.push({ id: doc, title });
      }
    }
  }
  return docs;
}

function useDocs() {
  return useState<Array<Doc>>(() => getDocsFromLocalStorage());
}

export default function DocsIndex(props: Props) {
  const [docs, setDocs] = useDocs();
  const windowSize = useWindowSize();

  function deleteDoc(targetDocId: string) {
    const doc = docs.find(d => d.id === targetDocId);
    if (doc) {
      if (window.confirm(`Are you sure you want to remove "${doc.title}"? This cannot be undone.`)) {
        // Side-effects
        localStorage.removeItem(`doc:${targetDocId}`);
        localStorage.removeItem(`doctitle:${targetDocId}`);

        // Refresh
        setDocs(getDocsFromLocalStorage());
      }
    }
  }

  const random = randomize('Aa0', 16);
  return (
    <AppContainer
      heights={['auto', '1fr']}
      style={{
        backgroundColor: '#efefef',
        height: windowSize.height,
      }}
    >
      <header>
        <Menu docId={null} />
      </header>
      <div style={{ padding: 40 }}>
        <Container>
          <Row>
            <Col>
              <Alert color="warning">
                <strong>Heads-up!</strong> Graphs are currently stored in local storage only, so they aren't shareable
                yet. (Working on that.)
              </Alert>

              {docs.map(doc => (
                <DocRow key={doc.id} to={`/doc/${doc.id}`}>
                  <Vsplit>
                    <FlexLeft>
                      <Icon.File />
                      <Black>{doc.title}</Black>
                    </FlexLeft>
                    <FlexRight>
                      <Icon.Trash2
                        color="#bbb"
                        onClick={e => {
                          e.preventDefault();
                          deleteDoc(doc.id);
                        }}
                      />
                    </FlexRight>
                  </Vsplit>
                </DocRow>
              ))}
              <div style={{ padding: 20 }}>
                <NavLink to={`/doc/${random}`} className="btn btn-lg btn-primary">
                  <Icon.Plus /> New
                </NavLink>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </AppContainer>
  );
}
