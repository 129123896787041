// @flow

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Doc from './components/Doc';
import DocsIndex from './components/DocsIndex';
import Home from './components/Home';
import NotFound from './components/NotFound';

export default () => (
  <Switch>
    <Route path="/doc/:docId" component={Doc} />
    <Route exact path="/docs" component={DocsIndex} />
    <Route exact path="/" component={Home} />
    <Route component={NotFound} />
  </Switch>
);
