// @flow

import GraphCtx from 'contexts/Graph';
import SetGraphCtx from 'contexts/SetGraph';
import type { Edge, Graph, Point } from 'lib/graphdown';
import { shiftPoint } from 'lib/graphdown/geometry';
import React, { useContext } from 'react';
import { HotKeys } from 'react-hotkeys';
import styled from 'styled-components/macro';

import bg from '../img/bg.png';
import NodeBox from './NodeBox';

type Props = {|
  showCompletedNodes: boolean,
  editSelection: () => mixed,
  handlers: {
    +[string]: () => mixed,
  },
|};

const MIN_CANVAS_WIDTH = 1200;
const MIN_CANVAS_HEIGHT = 800;

const CanvasBackgroundAndKeyboardHandler = styled(HotKeys)`
  flex: 1;
  position: relative;
  background: url(${bg});
  background-color: white;

  overflow: scroll;
  scroll-behavior: smooth;
`;

const ARROW_COLOR = '#bbb';
const PADDING = 10;

function arrowCurve({ src, dest, index, numChildren, relPos }: Edge): string {
  const x = src.topLeft.x + PADDING + ((index + 1) / (numChildren + 1)) * (src.size.width - 2 * PADDING);
  const from_: Point = { x, y: src.topCenter.y + 10 };

  const to: Point = shiftPoint(dest.bottomCenter, { x: relPos, y: 6 });
  return [
    `M${from_.x} ${from_.y}`,
    `C ` +
      // Control point 1
      `${from_.x} ${from_.y - 50}, ` +
      // Control point 2
      `${to.x - (to.x - from_.x) * 0.2} ${to.y - (to.y - from_.y) * 0.8}, ` +
      // End point
      `${to.x} ${to.y}`,
  ].join(' ');
}

function useGraph(): [Graph, (Graph) => mixed] {
  const graph: Graph = useContext(GraphCtx);
  const setGraph: Graph => mixed = useContext(SetGraphCtx);
  return [graph, setGraph];
}

export default function Canvas(props: Props) {
  const [graph, setGraph] = useGraph();

  const nodes = graph.allNodes(props.showCompletedNodes);
  const edges = graph.allEdges(props.showCompletedNodes);

  // IDs of nodes that are our Mikado Goals
  const goals = new Set(graph.getRoots().map(node => node.id));

  // Make SVG canvas large enough to contain at least the entire graph
  const bbox = graph.getBoundingBox();
  const canvasWidth = Math.max(MIN_CANVAS_WIDTH, bbox.width) + 2 * 30;
  const canvasHeight = Math.max(MIN_CANVAS_HEIGHT, bbox.height) + 2 * 30;

  const handleClick = () => {
    setGraph(graph.selectNode(null));
  };

  return (
    <CanvasBackgroundAndKeyboardHandler handlers={props.handlers}>
      <div style={{ width: canvasWidth, height: canvasHeight }}>
        <svg width={canvasWidth} height={canvasHeight} onClick={handleClick}>
          <defs>
            <marker id="head" orient="auto" markerWidth="6" markerHeight="6" refX="4" refY="3">
              {/* Triangle pointing right (+x) */}
              <path d="M1,1 L5,3 L1,5 Z" fill={ARROW_COLOR} stroke={ARROW_COLOR} />
            </marker>
          </defs>
          <g>
            {edges.map(edge => (
              <path
                key={`${String(edge.src.id)}->${String(edge.dest.id)}`}
                d={arrowCurve(edge)}
                stroke={ARROW_COLOR}
                strokeWidth={2}
                fill="transparent"
                markerEnd="url(#head)"
              />
            ))}
          </g>
        </svg>
        {nodes.map((node, i) => (
          <NodeBox
            key={String(node.id)}
            node={node}
            isGoal={goals.has(node.id)}
            isSelected={node.id === graph.selectedNodeId}
            editSelection={props.editSelection}
          />
        ))}
      </div>
    </CanvasBackgroundAndKeyboardHandler>
  );
}
