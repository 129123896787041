// @flow strict

import theme from 'config/theme';
import randomize from 'randomatic';

import type { Point, Size } from './geometry';
import { createMeasurer } from './geometry';
import { extractLinks } from './parse';

const measureNormalText = createMeasurer(document, `${theme.FONT_SIZE}px "${theme.FONT_FAMILY}"`);
const measureBoldText = createMeasurer(document, `700 ${theme.FONT_SIZE}px "${theme.FONT_FAMILY}"`);

export type NodeId = string;

type NodeFields = {|
  // Core to the data
  +id: NodeId,
  +title: string,
  +parents: Array<NodeId>,
  +isCompleted: boolean,
  +links: Array<string>,

  // Appearance on screen
  +center?: Point,
|};

const _G = { count: 1 };
const newId = (): NodeId => {
  return process.env.NODE_ENV === 'test' ? `node${_G.count++}` : randomize('Aa0', 12);
};
export const forceNodeId = (value: string): NodeId => {
  return value;
};

function measureTextBox(text: string, isBold: boolean): Size {
  const textWidth = isBold ? measureBoldText(text) : measureNormalText(text);
  const actualWidth = textWidth;
  let width = actualWidth;
  width = Math.min(theme.MAX_NODE_WIDTH, width);
  const numLines = Math.ceil(actualWidth / theme.MAX_NODE_WIDTH);
  const height = numLines * theme.LINE_HEIGHT;
  return {
    width: Math.ceil(width),
    height: Math.ceil(height),
  };
}

function boundingBox(text: string, isBold: boolean, numLinks: number): Size {
  // Keep these in sync with the CSS markup in NodeBox.css - is there a better way?
  const { width, height } = measureTextBox(text, isBold);
  const LINKS_HEIGHT = numLinks > 0 ? 16 + numLinks * theme.LINE_HEIGHT + 4 : 0;
  return {
    width: width + theme.NODE_PADDING_LEFT + theme.NODE_PADDING_RIGHT + 2 * theme.BORDER_SIZE,
    height: height + theme.NODE_PADDING_TOP + theme.NODE_PADDING_BOTTOM + 2 * theme.BORDER_SIZE + LINKS_HEIGHT,
  };
}

export class Node {
  fields: NodeFields;

  // Persisted in graphdown
  id: NodeId;
  title: string;
  parents: Array<NodeId>;
  isCompleted: boolean;
  links: Array<string>;

  // Appearance (not persisted in graphdown)
  center: Point;
  topLeft: Point;
  topCenter: Point;
  bottomCenter: Point;
  bottomRight: Point;
  size: Size;

  constructor(fields: NodeFields) {
    this.id = fields.id;
    this.title = fields.title;
    this.parents = fields.parents;
    this.isCompleted = fields.isCompleted;
    this.links = fields.links;

    this.center = fields.center || { x: 0, y: 0 };

    // Pre-compute some convenient props
    this.size = boundingBox(this.title, this.parents.length === 0, this.links.length);
    const top = this.center.y - this.size.height / 2;
    const bottom = this.center.y + this.size.height / 2;
    const left = this.center.x - this.size.width / 2;
    this.topLeft = { x: left, y: top };
    this.topCenter = { x: this.center.x, y: top };
    this.bottomCenter = { x: this.center.x, y: bottom };
    this.bottomRight = { x: left + this.size.width, y: top + this.size.height };

    this.fields = {
      id: this.id,
      title: this.title,
      links: this.links,
      parents: this.parents,
      isCompleted: this.isCompleted,
      center: this.center,
    };
  }

  static create(
    rawTitle: string,
    parents: Array<NodeId> = [],
    isCompleted: boolean = false,
    id: NodeId = newId(),
  ): Node {
    const links = [];
    return new Node({ id, title: '', parents, isCompleted, links }).setRawTitle(rawTitle);
  }

  /**
   * Updates the graph with the given properties.
   */
  update(record: $Shape<NodeFields>): Node {
    return new Node({
      id: record.id !== undefined ? record.id : this.id,
      title: record.title !== undefined ? record.title : this.title,
      parents: record.parents !== undefined ? record.parents : this.parents,
      isCompleted: record.isCompleted !== undefined ? record.isCompleted : this.isCompleted,
      links: record.links !== undefined ? record.links : this.links,
      center: record.center !== undefined ? record.center : this.center,
    });
  }

  shift(delta: Point): Node {
    const cx = this.center.x;
    const cy = this.center.y;
    const dx = delta.x;
    const dy = delta.y;
    const center = { x: cx + dx, y: cy + dy };
    return this.setCenter(center);
  }

  setTopLeft(topLeft: Point): Node {
    const center = {
      x: topLeft.x + this.size.width / 2,
      y: topLeft.y + this.size.height / 2,
    };
    return this.setCenter(center);
  }

  setCenter(center: Point): Node {
    return this.update({ center });
  }

  toggle(): Node {
    return this.setDone(!this.isCompleted);
  }

  setDone(isCompleted: boolean): Node {
    return this.update({ isCompleted });
  }

  setTitle(title: string): Node {
    return this.update({ title });
  }

  rawTitle(): string {
    let line = this.title;
    if (this.links.length > 0) {
      line += ' ';
      line += this.links.join(' ');
    }
    return line;
  }

  setRawTitle(titleWithLinks: string): Node {
    const [title, links] = extractLinks(titleWithLinks);
    return this.update({ title, links });
  }

  setParents(parents: Array<string>): Node {
    return this.update({ parents });
  }
}
