// @flow strict

import { parse } from 'lib/graphdown';
import type { Graph } from 'lib/graphdown';
import { createContext } from 'react';

export const initialState: Graph = parse(`
[ ] Remove SQL from wallets.js
 [ ] Remove custom decoders
   [ ] Remove validType decoder
     [ ] Replace custom type by model type
 [ ] Remove \`_testAddEntry\` helper
   [ ] Replace WalletEntry custom type by WalletTransaction
`);

/**
 * Represents the "global Graph", and its allowed operations, triggered from
 * the UI.
 */
export default createContext<Graph>(initialState);
