// @flow

import theme from 'config/theme';
import GraphCtx from 'contexts/Graph';
import SetGraphCtx from 'contexts/SetGraph';
import { Node } from 'lib/graphdown';
import { useScrollIntoView } from 'lib/hooks';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';

import ShortLink from './ShortLink';

type Props = {|
  node: Node,
  isGoal: boolean,
  isSelected: boolean,
  editSelection: () => mixed,
|};

/**
 * States:
 *
 *    isGoal = has double border
 *    isCompleted = is green
 *    isSelected = has blue outline/glow
 *
 */

type BoxProps = {|
  isGoal: boolean,
  isSelected: boolean,
  isCompleted: boolean,
|};

const borderColor = (props: BoxProps) => (props.isCompleted ? '#82c57b' : props.isGoal ? '#444' : '#aaa');
const boxShadow = (props: BoxProps, hover: boolean = false) => {
  let offset = 0;
  const rings = [];

  // If goal, then we need to draw a double-border
  if (props.isGoal) {
    rings.push(`0 0 0 ${offset + 2}px #fff`);
    offset += 2;
    rings.push(`0 0 0 ${offset + 2}px ${borderColor(props)}`);
    offset += 2;
  }

  if (props.isSelected) {
    rings.push(`0 0 0 ${offset + 3}px rgba(93, 162, 255, 0.6)`);
    offset += 3;
  }

  if (hover) {
    rings.push(`0 0 8px ${offset}px #ddd`);
    offset += 8;
  }

  if (rings.length === 0) return 'none';
  return rings.join(', ');
};

const Box = styled.div`
  font-family: ${theme.FONT_FAMILY};
  font-size: ${theme.FONT_SIZE}px;
  font-weight: ${props => (props.isGoal ? '700' : '400')};
  line-height: ${theme.LINE_HEIGHT}px;

  cursor: pointer;
  position: absolute;
  margin: 0;
  padding: ${theme.NODE_PADDING_TOP}px ${theme.NODE_PADDING_RIGHT}px ${theme.NODE_PADDING_BOTTOM}px
    ${theme.NODE_PADDING_LEFT}px;
  border-radius: 8px;
  text-align: center;
  border-width: ${theme.BORDER_SIZE}px;
  border-style: solid;

  /* Make user text selection impossible */
  user-select: none;

  /* Very wide text that cannot be wrapped will be clipped */
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    box-shadow: ${props => boxShadow(props, true)};
  }

  color: ${props => (props.isCompleted ? '#28a745' : '#444')};
  background-color: ${props => (props.isCompleted ? '#edffed' : '#fff')};
  border-color: ${borderColor};
  box-shadow: ${boxShadow};

  hr {
    padding: 0;
    margin: 10px 0 5px 0;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    height: 0px;
  }
`;

export default (props: Props) => {
  const graph = useContext(GraphCtx);
  const setGraph = useContext(SetGraphCtx);
  const el = useScrollIntoView(props.isSelected);

  const handleClick = () => {
    setGraph(graph.selectNode(props.node.id));
  };

  const node = props.node;
  const x = node.topLeft.x;
  const y = node.topLeft.y;
  const { width, height } = node.size;
  const text = node.title;
  const isGoal = props.isGoal;
  const isCompleted = node.isCompleted;
  const isSelected = props.isSelected;
  return (
    <Box
      isGoal={isGoal}
      isCompleted={isCompleted}
      isSelected={isSelected}
      ref={el}
      style={{ left: x, top: y, width, height }}
      onClick={handleClick}
      onDoubleClick={props.editSelection}
    >
      {text}
      {node.links.length > 0 ? (
        <>
          <hr />
          {node.links.map(url => (
            <ShortLink key={url} url={url} />
          ))}
        </>
      ) : null}
    </Box>
  );
};
