// @flow

import theme from 'config/theme';
import React from 'react';
import * as Icon from 'react-feather';
import styled from 'styled-components/macro';

type LinkProps = {|
  url: string,
|};

const scheme_re = /^(?:[a-z]+:\/\/)/i;
const asana_re = /asana\.com\/(.+)/i;
const github_re = /github\.com\/(.+)/i;
const slack_re = /slack\.com\/(.+)/i;

// Icons
const SIZE = theme.FONT_SIZE;
const ICONS = {
  ASANA: <Icon.Triangle size={SIZE} />,
  GITHUB: <Icon.GitHub size={SIZE} />,
  SLACK: <Icon.Slack size={SIZE} />,
  UNKNOWN: <Icon.Paperclip size={SIZE} />,
};

type LinkType = $Keys<typeof ICONS>;

/**
 * Renders specific GitHub URLs shorter.
 */
function simplifyGitHubUrl(url: string): string | null {
  let match = url.match(github_re);
  if (!match) {
    return null;
  }

  const path = match[1];
  const pr_number_match = path.match(/\/pull\/([0-9]+)(?:\/([^/#?]+))?/i);
  if (pr_number_match) {
    return pr_number_match[2] ? `PR #${pr_number_match[1]} (${pr_number_match[2]})` : `PR #${pr_number_match[1]}`;
  }

  const issue_number_match = path.match(/\/issues\/([0-9]+)/i);
  if (issue_number_match) {
    return `Issue #${issue_number_match[1]}`;
  }

  // TODO: Do the same for blob/tree/commit
  // https://github.com/nvie/decoders/blob/master/src/array.js#L9-L12
  return path;
}

function shorten(url: string): [LinkType, string] {
  // Try GitHub
  const githubUrl = simplifyGitHubUrl(url);
  if (githubUrl) {
    return ['GITHUB', githubUrl];
  }

  // Try Asana
  let match = url.match(asana_re);
  if (match) {
    return ['UNKNOWN', 'Asana'];
  }

  // Try Slack
  match = url.match(slack_re);
  if (match) {
    return ['SLACK', 'Slack'];
  }

  // Normal link
  return ['UNKNOWN', url.replace(scheme_re, '')];
}

const Linky = styled.div`
  font-size: ${theme.FONT_SIZE}px;
  line-height: ${theme.LINE_HEIGHT}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  a {
    text-decoration: none;
    // color: #444;
  }
`;

export default function ShortLink(props: LinkProps) {
  const [type, shortUrl] = shorten(props.url);
  const icon = ICONS[type];
  return (
    <Linky>
      {icon}{' '}
      <a href={props.url} target="_blank" rel="noopener noreferrer" title={props.url}>
        {shortUrl}
      </a>
    </Linky>
  );
}
