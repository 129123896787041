// @flow

import { useEffect, useRef } from 'react';

/**
 * Returns a ref which will automatically be scrolled into view once the
 * `shouldScrollNow` value becomes truthy.
 */
export default function useScrollIntoView(shouldScrollNow: boolean) {
  const domElement = useRef<HTMLElement | null>(null);

  useEffect(
    () => {
      // If it became selected and wasn't before, scroll it into view now!
      if (shouldScrollNow) {
        const el = domElement.current;
        if (el) {
          el.scrollIntoView({
            behaviour: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    },
    [shouldScrollNow],
  );

  return domElement;
}
