// @flow

import { useEffect, useState } from 'react';

function getSize() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  return { width, height };
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount/unmount

  return windowSize;
}
