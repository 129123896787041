// @flow

import styled from 'styled-components';

const DEFAULT_WIDTHS = ['50%', '50%'];

export default styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: ${props => (props.widths || DEFAULT_WIDTHS).join(' ')};
  flex: 1;

  > * {
    overflow: hidden;
    ${props => (props.debug ? 'border: 2px solid red;' : '')};
  }
`;
