// @flow strict

import type { Point, Size } from './geometry';
import { Graph } from './Graph';
import type { Edge } from './Graph';
import { Node } from './Node';
import parse from './parse';
import serialize from './serialize';

function prettify(text: string): string {
  return serialize(parse(text));
}

export type { Edge, Point, Size };
export { Graph, Node, parse, prettify, serialize };
