// @flow

import React from 'react';
import * as Icon from 'react-feather';
import { Button } from 'reactstrap';
import styled from 'styled-components/macro';

import { FlexLeft, FlexRight, Vsplit } from '../lib/ui';

type Props = {|
  +showCompletedNodes: boolean,
  +setShowCompletedNodes: boolean => mixed,
  +debuggerVisible: boolean,
  +setDebuggerVisible: boolean => mixed,
  +selectionToggleDone: () => mixed,
  +selectionEditTitle: () => mixed,
|};

const ToolbarArea = styled.div`
  padding: 0 20px;

  border: 1px solid #e0e0e0;
  background-color: #fff;

  .btn {
    border: 1px solid transparent;
    border-radius: 0;
    height: 34px;
  }
`;

export default function Toolbar(props: Props) {
  const {
    showCompletedNodes,
    setShowCompletedNodes,
    debuggerVisible,
    setDebuggerVisible,
    selectionToggleDone,
    selectionEditTitle,
  } = props;
  return (
    <ToolbarArea>
      <Vsplit>
        <FlexLeft>
          <Button outline color="primary" size="sm">
            <Icon.Target /> Goal
          </Button>
          <Button outline color="primary" size="sm">
            <Icon.Plus /> Prerequisite
          </Button>
          <Button outline size="sm" onClick={() => selectionToggleDone()}>
            <Icon.Check /> Toggle
          </Button>
          <Button outline size="sm" onClick={() => selectionEditTitle()}>
            <Icon.Edit2 /> Edit
          </Button>
        </FlexLeft>
        <FlexRight>
          <Button outline size="sm" onClick={() => setDebuggerVisible(!debuggerVisible)}>
            <Icon.Layout /> {debuggerVisible ? 'Hide debugger' : 'Show debugger'}
          </Button>
          <Button outline size="sm" onClick={() => setShowCompletedNodes(!showCompletedNodes)}>
            {showCompletedNodes ? <Icon.EyeOff /> : <Icon.Eye />}{' '}
            {showCompletedNodes ? 'Hide completed' : 'Show completed'}
          </Button>
        </FlexRight>
      </Vsplit>
    </ToolbarArea>
  );
}
