// @flow strict

import { compose, predicate, regex } from 'decoders';
import type { Decoder } from 'decoders';

const iso8601_re = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:[.]\d+)?(?:Z|[+-]\d{2}:?\d{2})$/;

// Doing this makes sure that semantically the digits in this date are valid,
// too (for example 2018-56-33 and 2018-02-29 are NOT valid dates)
const is_valid_datestring = predicate(
  value => iso8601_re.test(new Date(value).toISOString()),
  'Must be valid date/time value',
);

export const iso8601: Decoder<string> = compose(
  // Input itself needs to match the ISO8601 regex...
  regex(iso8601_re, 'Must be ISO8601 format'),
  is_valid_datestring,
);
