// @flow

import { flextree } from 'd3-flextree';
import { flatmap } from 'itertools';
import { Graph, Node } from 'lib/graphdown';
import type { Point, Size } from 'lib/graphdown';
import { forceNodeId } from 'lib/graphdown/Node';
import type { NodeId } from 'lib/graphdown/Node';

const VSPACING = 50; // Vertical spacing between nodes
const HSPACING = 40; // Horizontal space between nodes

type FlexTreeNode$Output = {|
  x: number,
  y: number,
  size: [number, number],
  children: Array<FlexTreeNode$Output>,
  data: {
    node: Node,
  },
|};

type Primitive = number | string | NodeId;

function indexBy<T, K: Primitive>(items: Array<T>, keyFn: T => K): Map<K, T> {
  const lut = new Map();
  for (const item of items) {
    lut.set(keyFn(item), item);
  }
  return lut;
}

/**
 * Turns a d3-flextree hierarchy into a flat list of Nodes with their x/y
 * values set.
 */
function flattenHierarchy(tree: FlexTreeNode$Output): Array<{| +id: NodeId, +center: Point |}> {
  const node: Node = tree.data.node;
  const id = node.id;
  const x = tree.x;
  const y = tree.y;

  // d3-flextree computes a top-down tree, so we'll try flipping it vertically
  // (growing upwards from the bottom)
  const center = {
    x: x - HSPACING / 2,
    y: -y - node.size.height / 2,
  };

  const curr = { id, center };
  const rest = Array.from(flatmap(tree.children || [], child => flattenHierarchy(child)));
  return [curr, ...rest];
}

function unfoldBackToGraph(graph, tree: FlexTreeNode$Output): Graph {
  const posLUT = indexBy(flattenHierarchy(tree), n => n.id);
  const nodes = graph.allNodes().map(node => {
    const pos = posLUT.get(node.id);
    return pos
      ? node.setCenter(pos.center)
      : // Arbitrary point, it won't be drawn anyway
        node.setCenter({ x: 0, y: 0 });
  });
  return (
    Graph.of(...nodes)
      // Keep the current selection
      .selectNode(graph.selectedNodeId)
  );
}

/**
 * Given a Graph (graph of Nodes), auto-layout each node in a visual
 * representation on screen.
 *
 * Input is a structural description of the graph.  Output is a detailed
 * description of the elements to draw on screen.
 */
function layoutGraph(graph: Graph, showCompleted: boolean): Graph {
  const fakeRoot = Node.create('__ROOT__', [], false, forceNodeId('__ROOT__'));
  const root = {
    node: fakeRoot,
    children: graph.toTree(showCompleted),
  };

  //
  // Convert this graph to a node hierarchy, suitable for d3-flextree to work
  // with.  d3-flextree expects a hierarchy to be a single tree starting at
  // a root, and where each node in the tree can have a bunch of children.  Since
  // the Graph uses a DAG structure, where each node keeps a reference to its
  // parents, we'll have to represent the tree the other way around.
  //
  // NOTE: A limitation of d3-flextree is that children cannot be shared between
  // nodes (i.e. each node has exactly one parent).
  //
  // We're only using d3-flextree to compute (x, y) coordinates for us, all the
  // structures that are required to be inputted and are outputted by d3-flextree
  // are discarded after its computation is done.
  //
  const layout = flextree({
    children: data => data.children,
    nodeSize: treenode => {
      const node: Node = treenode.data.node;
      const size = node.size;
      return [size.width + HSPACING, size.height + VSPACING];
    },
    spacing: 0,
  });
  const tree = layout.hierarchy(root);
  layout(tree);

  const newGraph = unfoldBackToGraph(graph, tree);
  const boundingBox = newGraph.getBoundingBox();
  return newGraph.shift({ x: -boundingBox.x + 30, y: -boundingBox.y + 30 });
}

export default layoutGraph;
export type { Node, Point, Size };
