// @flow

import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import logo from '../img/logo.svg';
import { FlexLeft, FlexRight, Vsplit } from '../lib/ui';

type Props = {|
  docId: string | null,
|};

const MenuArea = styled.header`
  background-color: white;
  border-bottom: 1px solid #e0e0e;
  padding: 10px;
  margin: 0;

  display: flex;
  align-items: center;

  > * {
    margin: 4px 16px 4px 8px;
  }

  .FlexLeft > * {
    margin-right: 10px;
  }
`;

function EditableDocTitle(props: {| docId: string |}) {
  const docId = props.docId;
  const [title, setTitle] = useState(() => localStorage[`doctitle:${docId}`] || docId);

  function editDocTitle() {
    if (!docId) return;

    const newTitle = window.prompt('New document title:', title);
    if (newTitle) {
      localStorage[`doctitle:${docId}`] = newTitle;
      setTitle(newTitle);
    }
  }

  return (
    <div>
      {title} <Icon.Edit2 size={16} color="#777" onClick={editDocTitle} />
    </div>
  );
}

export default (props: Props) => {
  const docId = props.docId;
  return (
    <MenuArea>
      <Vsplit widths={['1fr', '120px']}>
        <FlexLeft>
          <NavLink to="/docs">
            <img alt="Mikado logo" src={logo} height={30} />
          </NavLink>
          {docId ? (
            <>
              <Icon.ChevronRight />
              <div className="bold">
                <EditableDocTitle docId={docId} />
              </div>
            </>
          ) : null}
        </FlexLeft>
        <FlexRight>
          {/* Login/Account */}
          &nbsp;
        </FlexRight>
      </Vsplit>
    </MenuArea>
  );
};
