// @flow strict

export type Point = {|
  x: number,
  y: number,
|};

export type Size = {|
  width: number,
  height: number,
|};

export type Rect = {|
  ...Point,
  ...Size,
|};

export function shiftPoint(p: Point, delta: Point): Point {
  return {
    x: p.x + delta.x,
    y: p.y + delta.y,
  };
}

export function createMeasurer(document: Document, fontStyle: string) {
  // If we cannot get a drawing context (i.e. in a test environment), just
  // return a fixed width
  if (process.env.NODE_ENV === 'test') {
    return (text: string) => 300;
  }

  const drawingContext = document.createElement('canvas').getContext('2d');
  drawingContext.font = fontStyle;
  drawingContext.textAlign = 'left';
  drawingContext.textBaseline = 'top';
  return (text: string): number => {
    const metrics = drawingContext.measureText(text);
    return metrics.width;
  };
}
